export const types = {
    SET_TX_LOADER: "SET_TX_LOADER",
    SET_MAX_APR: "SET_MAX_APR",
    SET_TELEMETRY_REWARDS: "SET_TELEMETRY_REWARDS",
    SET_ACTIVE_TX: "SET_ACTIVE_TX",
    SET_TELEMETRY_ASSETS: "SET_TELEMETRY_ASSETS",
    SET_REWARDS: "SET_REWARDS",
    SET_ALLOWANCE: "SET_ALLOWANCE",
    SET_LP_INFO: "SET_LP_INFO",
    SET_PRICE: "SET_PRICE",
    SET_ALLOWANCE_ONE: "SET_ALLOWANCE_ONE"
}