/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Card from "./Card";
import Overlay from "./Overlay";
import { NumberInput } from "./Input";
import { useState, useContext, useEffect } from "react";
import { FiDownload, FiInfo } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";
import Button from "./Button";
import toast from "react-hot-toast";
// import { useAllowance } from "../hooks/useAllowance";
import { DEFAULT_CHAINID, toHex } from "../utils/constants";
import { useApprove } from "../hooks/useApprove";
import { useTokenBalance } from "../hooks/useTokenBalance";
import ApiClient from "../api/ApiClient";
// import Timer from "./Timer";
import {
  useIngameUserInfo,
  useStake,
  useUnstake,
  useClaim,
} from "../hooks/useIngame";
import { useWeb3React } from "@web3-react/core";
import { parseEther } from "ethers/lib/utils";
import { useNFTPendings } from "../hooks/usePendings";
import { Context } from "../contextStore";
import useCommon from "../hooks/useCommon";
import Loader from "./Loader";
import { Link } from "react-router-dom";

export default function NewImgameStaking({
  MIN_STAKE_AMOUNT = 5000,
  MAX_STAKE_AMOUNT = 100000,
  pool_address = "",
  pool_label = "",
  pool_key = "",
}) {
  const stakingType = "smcw";
  const [enjinAddress, setEnjinAddress] = useState("");
  const [stakeAmount, setStakeAmount] = useState<string>("");
  const [unstakeAmount, setUnstakeAmount] = useState<string>("");
  const [claimAmount, setClaimAmount] = useState<string>("");
  const [type, setType] = useState<"stake" | "unstake">("stake");
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(null);
  const [{ active_tx, allowance }, ACTION] = useContext(Context);
  const { chainId, active, account } = useWeb3React();
  const { addCommasToNumber } = useCommon();

  const [actionType, setActionType] = useState<any>("default");

  const setActiveTx = (tx_string: string) => {
    console.log(tx_string);
    ACTION.SET_ACTIVE_TX(tx_string);
  };

  const actionMessage: any = {
    stake: "Staking...",
    claim: "Claiming...",
    default: "Increase / Stake",
  };

  const stake = useStake(pool_address);
  const unstake = useUnstake();
  const claim = useClaim(pool_address);

  const { pendings, estimated } = useNFTPendings(isLoading, pool_address);
  const userInfo = useIngameUserInfo(isLoading, pool_address);
  const approve = useApprove(stakingType);
  const smcwBalance = useTokenBalance(stakingType, isLoading);

  useEffect(() => {
    console.log(pool_key);
    console.log("$$$", allowance[pool_key]);
    setIsApproved(allowance[pool_key]);
  }, [allowance]);

  const handleStake = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    ACTION.SET_TX_LOADER(true);
    setIsLoading(true);
    setActionType("stake");
    setActiveTx("TELEMETRY_STAKE");
    const reqBody = {
      staker_address: account || "",
      pool_address: pool_address,
      amount: Number(stakeAmount) || 0,
      coin_ticker: "SMCW",
      pool: `Hidden data (Random Telemetry) ${pool_label}`,
    };
    if (
      Number(stakeAmount) +
        Number(userInfo.stakedAmount) +
        Number(userInfo.lastAmount) >
      MAX_STAKE_AMOUNT
    ) {
      toast.error("Max staked amount reached");
      setIsLoading(false);
      setActionType("default");
      setActiveTx("");
      return;
    }
    try {
      await stake(parseEther(stakeAmount).toString());
      await new ApiClient().postStaking(reqBody);
      ACTION.SET_TX_LOADER(false);
      toast.success(`Stake Amount: ${stakeAmount}`);
      setIsLoading(false);
      setActionType("default");
      setActiveTx("");
      // window.location.reload();
    } catch (err) {
      setActionType("default");
      ACTION.SET_TX_LOADER(false);
      setIsLoading(false);
      setActiveTx("");
      console.log(err);
    }
  };

  const handleUnstake = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(unstakeAmount);
    setIsLoading(true);
    ACTION.SET_TX_LOADER(true);
    setActiveTx("TELEMETRY_UNSTAKE");
    try {
      // await unstake(parseEther(unstakeAmount).toString());
      ACTION.SET_TX_LOADER(false);
      toast.success(`Unstake Amount: ${unstakeAmount}`);
      setIsLoading(false);
      setActiveTx("");
      // window.location.reload();
    } catch (err) {
      ACTION.SET_TX_LOADER(false);
      setIsLoading(false);
      console.log(err);
      setActiveTx("");
    }
  };

  const handleClaim = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log("HANDLE CLAIM");
    e.preventDefault();
    // @note integrate enjin apis and then execute claim transaction
    ACTION.SET_TX_LOADER(true);
    setIsLoading(true);
    setActionType("claim");
    setActiveTx("TELEMETRY_CLAIM");
    try {
      if (enjinAddress.length !== 42) {
        toast.error("Address is not correct");
      }
      const tx = await claim(claimAmount, enjinAddress);
      setTimeout(async () => {
        const rewardsResponse = await new ApiClient().getRewardsByTxHash(
          tx.hash
        );
        ACTION.SET_REWARDS(rewardsResponse);
        toast.success(`Claimed successfully`);
        ACTION.SET_TX_LOADER(false);
        setIsLoading(false);
        setActionType("default");
        setActiveTx("");
        setClaimAmount("0");
      }, 8000);
    } catch (error) {
      console.log(error);
      ACTION.SET_TX_LOADER(false);
      setIsLoading(false);
      toast.error("Something went wrong");
      setActionType("default");
      setActiveTx("");
      setClaimAmount("0");
    }
  };

  return (
    <Card className="flex-1">
      {/* <div className="grid grid-cols-1 gap-4"> */}
      <p
        className="text-md bgTransparent ingame-info"
        style={{ padding: "16px 24px" }}
      >
        <div className="flex items-center mb-2">
          <FiInfo className="text-design-darkBlue2 mr-2" /> INFO{" "}
        </div>
        The Ingame pool has closed its service until we deploy a new pool using
        BNB Smart Chain as per our migration from Enjin. <br />
        You can unstake using the <a href="/vesting">Claim/Vesting Panel</a>.
        <br />
        <div className="mt-4">
          If you had pending rewards, please fill up a ticket in our{" "}
          <a href="https://discord.gg/spacemisfits">Discord Support</a> tickets
          and we will send you random Telemetry after verifying your wallet.
        </div>
      </p>
      {/* </div> */}
    </Card>
  );
}
